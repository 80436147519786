$background-color: #000;
$color: #fff;
$base-unit: 1em;
$font-size: 18px;
$chassis-background: #7b8e78;
$chassis-margin: $base-unit * 3;
$interlace-background: linear-gradient(#888 50%, #000 50%);
$interlace-size: 4px;
$envelope-background: rgba(#000, 0.25);
$envelope-margin: 2em;
$scanline-background: linear-gradient(to bottom, transparent 0%, rgba(255, 250, 250, 1) 50%, rgba(100, 255, 100, 1) 50%, transparent 100%);
$output-font-family: 'monofont';
$output-color: #18ff62;
$output-text-shadow: rgba(10, 255, 10, 0.8);
$output-line-height: 1.25;
$gradient-bottom: rgb(0, 30, 30);
$gradient-inner: rgb(0, 255, 119);

@mixin fill($top: 0, $left: 0, $right: 0, $bottom: 0) {
  position: absolute;
  left: $left;
  top: $top;
  right: $right;
  bottom: $bottom;
}
