body,
html {
  width: 100%;
  height: 100%;
}

body {
  background-color: $background-color;
  font-size: 14px;
  font-family: sans-serif;
  color: $color;
  display: flex;
  align-items: center;
  justify-content: center;
}

#info {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: $base-unit / 2;
}

#ga {
  position: absolute;
  bottom: 0;
  left: 0;
}
